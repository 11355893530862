import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Opinion Groups`}</h1>
    <p>{`A conversation can often be thought of as being composed of `}<a parentName="p" {...{
        "href": "/opinion-group",
        "title": "opinion group"
      }}>{`opinion group`}</a>{`s (subsets of the participant body which tend to agree with each other).
These `}<a parentName="p" {...{
        "href": "/opinion-group",
        "title": "opinion group"
      }}>{`opinion group`}</a>{`s are central to how we think about and get value out of a conversation.`}</p>
    <p>{`As a tool, `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{`' goal is to:`}</p>
    <ul>
      <li parentName="ul">{`reflect back to participants an understanding of themselves in relation to the `}<a parentName="li" {...{
          "href": "/opinion-landscape",
          "title": "opinion landscape"
        }}>{`opinion landscape`}</a>{``}</li>
      <li parentName="ul">{`surface points of common ground or rough `}<a parentName="li" {...{
          "href": "/consensus",
          "title": "consensus"
        }}>{`consensus`}</a>{``}</li>
    </ul>
    <p>{``}<a parentName="p" {...{
        "href": "/Opinion-group",
        "title": "Opinion group"
      }}>{`Opinion group`}</a>{`s allow `}<a parentName="p" {...{
        "href": "/participant",
        "title": "participant"
      }}>{`participant`}</a>{`s to`}</p>
    <ul>
      <li parentName="ul">{`frame an understanding of their position in the opinion landscape relative to what opinion group they align with`}</li>
      <li parentName="ul">{`understand where people who think differently than they do tend to fall on the issues by reviewing the `}<a parentName="li" {...{
          "href": "/representative-comments",
          "title": "representative comments"
        }}>{`representative comments`}</a>{` for other opinion groups`}</li>
      <li parentName="ul">{`surface points of common ground by looking at `}<a parentName="li" {...{
          "href": "/group-informed-consensus",
          "title": "group informed consensus"
        }}>{`group informed consensus`}</a>{``}</li>
    </ul>
    <p>{`Generally speaking, opinion groups are able to accomplish this (and more) by serving as a lower dimensional representation of the full set of information in the conversation.
Instead of thinking about thousands of participants and comments, we can think about a handful of opinion groups and the key comments which help us understand them.
This allows us to weave a more coherent story about how these groups relate to each other, which would not be possible otherwise.`}</p>
    <h2>{`Clustering`}</h2>
    <p>{`There is no one "right way" to detect `}<a parentName="p" {...{
        "href": "/opinion-groups",
        "title": "opinion groups"
      }}>{`opinion groups`}</a>{` within a conversation.
Many algorithms exist for doing this, and are generally considered `}<a parentName="p" {...{
        "href": "/clustering-algorithms",
        "title": "clustering algorithms"
      }}>{`clustering algorithms`}</a>{`.
Groups within the conversation are typically identified with (or as) individual clusters.`}</p>
    <h3>{`K-means`}</h3>
    <p>{`Polis uses the `}<a parentName="p" {...{
        "href": "/k-means",
        "title": "k means"
      }}>{`k means`}</a>{` `}<a parentName="p" {...{
        "href": "/clustering-algorithm",
        "title": "clustering algorithm"
      }}>{`clustering algorithm`}</a>{` (`}<a parentName="p" {...{
        "href": "/clustering-algorithms",
        "title": "clustering algorithms"
      }}>{`clustering algorithms`}</a>{`) to group `}<a parentName="p" {...{
        "href": "/participants",
        "title": "participants"
      }}>{`participants`}</a>{` into clusters based on similarity of responses.`}</p>
    <p>{`K-means is a very old and simple method.
In comparison with newer techniques, it is very limited in the kinds of patterns it can find.
While this does restrict the kinds of patterns the resulting opinion groups can reflect, it has the benefit of being relatively predictable and easy to interpret.`}</p>
    <h4>{`Selecting a number of clusters`}</h4>
    <p>{`Because the `}<a parentName="p" {...{
        "href": "/k-means",
        "title": "k means"
      }}>{`k means`}</a>{` algorithm depends on a fixed choice of `}<inlineCode parentName="p">{`K`}</inlineCode>{` (the number of clusters to be selected in the data set), Polis uses the `}<a parentName="p" {...{
        "href": "/silhouette-coefficient",
        "title": "silhouette coefficient"
      }}>{`silhouette coefficient`}</a>{` to select for an optimal number of clusters.`}</p>
    <h3>{`More advanced techniques`}</h3>
    <p>{`There are numerous `}<a parentName="p" {...{
        "href": "/clustering-algorithms",
        "title": "clustering algorithms"
      }}>{`clustering algorithms`}</a>{` out there that we are researching and trialing for downstream analysis.
However, we're unlikely to adopt any of these as part of the core real-time engine without careful consideration of the trade offs.`}</p>
    <p>{`It is one of our number one goals as an organization to researched and develop a well defined computational and sociological framework for evaluating these trade offs.`}</p>
    <p>{`See also:`}</p>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Opinion-group",
          "title": "Opinion group"
        }}>{`Opinion group`}</a>{``}</li>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Representative-Comments",
          "title": "Representative Comments"
        }}>{`Representative Comments`}</a>{` which help `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` identify different sides of the conversation.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      